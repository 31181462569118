.layout-content {
  color: #ffffff;
}

/*--------------------------- Navigation Bar ------------------------*/
.navbar-nav {
  font-family: "Open Sans", sans-serif;
}

.navbar-dark .navbar-brand {
  color: #fff;
  font-family: "Oswald", sans-serif;
}

.navbar-brand {
  font-size: 1.5rem;
}

.navbar-nav li {
  font-size: 16px;
  text-transform: capitalize;
  padding: 5px;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255);
}

/*--------------------------- Common ------------------------*/
h1,
h2,
h3 {
  font-family: "Oswald", sans-serif;
}

.container-fluid {
  padding: 0;
}

.section-container {
  margin-bottom: 35px;
}

.section {
  width: 85%;
  font-size: 16px;
  color: #000000;
  /* font-family: "PT Sans Narrow", sans-serif; */
  letter-spacing: 0.5px;
}

.section-header>h2 {
  color: #2c73d2;
  font-size: 40px;
  /* font-family: "Oswald", sans-serif; */
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

.section .row .col-md-6 {
  margin: 0px;
}

.section .sub-section {
  margin-top: 50px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11);
}

.section .sub-section .sub-section-header {
  text-align: center;
  text-transform: uppercase;
}

.section .sub-section h3 {
  position: relative;
  top: -22px;
  padding: 10px 14px;
  font-size: 20px;
  background-color: #2c73d2;
  color: #ffffff;
  display: inline;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

/*---------------------------- Home -----------------------*/
.home-container {
  background-color: #4f4c47;
  background: url("../public/assets/images/bg-image.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  width: 85%;
  padding: 60px;
  margin: 40px auto 30px auto;
  text-align: center;
}

.home-container .profile-introduction {
  margin: auto;
  padding: 1.5em 1.5em 0px 1.5em;
  text-align: center;
}

.home-container .profile-introduction>h1 {
  color: white;
  line-height: 1;
  font-size: 32px;
  margin-top: 18px;
  margin-bottom: 9px;
  font-weight: 700;
}

.home-container .profile-introduction>p {
  font-size: 15px;
  margin: 0;
}

.home-container .profile-introduction .profile-image>img {
  height: 150px;
  border-radius: 50%;
}

.home-container .social-media-link {
  padding: 10px 0;
}

.home-container .social-media-link i {
  font-size: 24px;
}

.home-container .social-media-link>a {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
  transition: 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-decoration: none;
  background-color: transparent;
}

.home-container .social-media-link a:hover {
  color: #3b5998;
}

.nav-link {
  padding: 0;
}

.home-container .btn-container {
  padding-top: 15px;
  text-align: center;
}

.home-container .btn-container a {
  color: #ffffff;
  padding: 17px 28px;
}

.home-container .btn-container .btn-outline-primary {
  border-color: #ffffff;
  margin: 10px;
  width: 180px;
}

.home-container .btn-container .btn-outline-primary:hover {
  border: none;
}

/*---------------------------- Introduction --------------------------*/

.section .sub-section p {
  font-size: 16px;
  padding: 4px;
  text-align: justify;
}

.sub-section .personal-info>ul {
  list-style-type: none;
  padding: 5px 30px;
}

.sub-section .personal-info>ul>li {
  padding: 2px 0px;
}

.sub-section .personal-info>ul>li>.label {
  display: inline-block;
  color: #757575;
  width: 80px;
}

.sub-section .personal-info>ul>li>.details {
  display: inline-block;
}

/*------------------------------------- Skills -----------------------------*/

.section .sub-section .skill-item {
  padding: 10px 20px;
}

.section .sub-section hr {
  margin: 0;
  padding: 0;
}

.skill-item .row .col-2 {
  padding: 0;
  font-size: 50px;
  text-align: center;
}

.skill-item .row .col-2 img {
  height: 65px;
  width: 65px;
}

.skill-item .row .col-2 .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: none;
  border-radius: 0;
  max-width: 100%;
}

.skill-item .row .col-10 {
  padding-top: 5px;
}

.section .sub-section .skill-item .row .col-10>p {
  margin: 0;
  padding: 0;
  color: #757575;
}

.section .sub-section .skill-item .row .col-10>p>label {
  text-transform: capitalize;
}
.section .sub-section .skill-item .row .col-10>p>.skill-value {
  float: right;
}

.section .sub-section .skill-item .skill-bar-container {
  background-color: #757575;
  margin-bottom: 13px;
  border-radius: 0px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  padding: 0;
  text-align: right;
  color: white;
}

.skill-bar-container .skill-bar {
  height: 15px;
}

.percent-90 {
  width: 90%;
  background-color: #2c73d2;
}

.percent-85 {
  width: 85%;
  background-color: #2c73d2;
}

.percent-80 {
  width: 80%;
  background-color: #2c73d2;
}

.percent-75 {
  width: 75%;
  background-color: #2c73d2;
}

.percent-70 {
  width: 70%;
  background-color: #2c73d2;
}

.percent-65 {
  width: 65%;
  background-color: #2c73d2;
}

/*---------------------------- Professional experience ------------------------*/

.main-timeline {
  width: 80%;
  margin: 0px auto;
  position: relative;
}

.main-timeline:before {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background-color: #ffffff;
  margin: 0 0 0 -1px;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-timeline .timeline {
  width: 100%;
  margin-top: 40px;
  position: relative;
}

.main-timeline .timeline:after {
  content: "";
  display: block;
  clear: both;
}

.main-timeline .timeline-content {
  width: 40%;
  float: left;
  margin: -15px 25px 0px 25px;
  border-radius: 6px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11);
}

.main-timeline .bullets {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #757575;
  padding: 5px 0;
  margin: 0 0 0 -7px;
  position: absolute;
  top: 0;
  left: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 1px #25303b;
}

.main-timeline .title {
  margin: 0;
  border-radius: 6px 6px 0 0;
  position: relative;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.main-timeline .title h3 {
  text-transform: capitalize;
  color: #2c73d2;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 15px;
}

.main-timeline .title:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 20px;
  right: -5px;
  transform: rotate(-45deg);
}

.main-timeline .description {
  padding: 15px;
  margin: 0;
  font-size: 14px;
  color: #656565;
  background: #fff;
  border-radius: 0 0 6px 6px;
}

.main-timeline .timeline:nth-child(2n + 2) .timeline-content {
  float: right;
}

.main-timeline .timeline:nth-child(2n + 2) .title:after {
  left: -5px;
}

.main-timeline .timeline:nth-child(1) .title,
.main-timeline .timeline:nth-child(1) .title:after {
  background: #ffffff;
  color: black;
}

.main-timeline .timeline:nth-child(2) .title,
.main-timeline .timeline:nth-child(2) .title:after {
  background: #ffffff;
  color: black;
}

.main-timeline .timeline:nth-child(3) .title,
.main-timeline .timeline:nth-child(3) .title:after {
  background: #ffffff;
  color: black;
}

.main-timeline .timeline:nth-child(4) .title,
.main-timeline .timeline:nth-child(4) .title:after {
  background: #ffffff;
}

.card {
  border: none;
}

.card-body {
  flex: 1 1 auto;
  padding: 0;
}

.card-body ul {
  padding-left: 20px;
}

.company-name {
  float: left;
  text-align: left;
  font-size: 15px;
}

.service-duration {
  text-align: right;
  font-size: 15px;
}

@media only screen and (max-width: 990px) {
  .main-timeline {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {

  .main-timeline:before,
  .main-timeline .bullets {
    left: 2%;
  }

  .main-timeline .timeline-content {
    width: 85%;
    float: right;
  }

  .main-timeline .title:after {
    left: -5px;
  }
}

@media only screen and (max-width: 480px) {

  .main-timeline:before,
  .main-timeline .bullets {
    left: 5%;
  }

  .main-timeline .timeline-content {
    width: 75%;
  }

  .main-timeline .bullets {
    width: 15px;
    height: 15px;
    margin-left: -8px;
  }

  .main-timeline .month {
    font-size: 14px;
  }
}

/*---------------------------- Services ------------------------*/

.service-item {
  text-align: center;
  padding: 20px;
  margin-top: 35px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.11);
  transition: all 0.3s ease-in-out;
}

.service-item .service-icon {
  font-size: 50px;
}

.service-item h3 {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  font-family: "PT Sans Narrow", sans-serif;
}

.service-item p {
  color: #444;
  text-align: justify;
}
/*--------------------------------- contact-section -------------------------------*/
.contact-section {
  margin-top: 35px;
  text-align: center;
}
.contact-section .contact-address-icon {
  font-size: 35px;
}
